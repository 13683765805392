import React, { ReactElement } from "react";
import style from "../../../styles/buttons.module.scss";

interface Props {
  text: string;
  className?: "primary" | "secondary" | "tertiary";
  fullWidth?: boolean;
  disabled?: boolean;
  onClick?: () => void;
}

export const Button = (props: Props): ReactElement => {
  const className = `${style[props.className || ""]} ${props.fullWidth ? style.fullWidth : ""}`;

  return (
    <button onClick={props.onClick} className={className} disabled={props.disabled || false}>
      {props.text}
    </button>
  );
};
