import React, { ReactElement } from "react";
import style from "../../../styles/buttons.module.scss";

interface Props {
  onClick: () => void;
}

const PlayButton = (props: Props): ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 60 60"
    width="24"
    height="24"
    className={`${style.circleButton} ${style.secondary}`}
    onClick={props.onClick}
    role="button"
  >
    <path d="M30 0C13.5 0 0 13.5 0 30s13.5 30 30 30 30-13.5 30-30S46.5 0 30 0zM45.6 30.8l-22 15C23.4 45.9 23.2 46 23 46c-0.2 0-0.3 0-0.5-0.1C22.2 45.7 22 45.4 22 45V15c0-0.4 0.2-0.7 0.5-0.9 0.3-0.2 0.7-0.1 1 0.1l22 15C45.8 29.4 46 29.7 46 30S45.8 30.6 45.6 30.8z" />
  </svg>
);

export default PlayButton;
