import React, { ReactElement } from "react";
import { timeFormatter } from "../../helpers/TimeFormatter/TimeFormatter";
import { Interval } from "../../interfaces/Intervals";

interface Props {
  intervals: Interval[];
}

const TotalTime = (props: Props): ReactElement => {
  const time = props.intervals.reduce((total: number, interval: Interval) => total + interval.seconds, 0);

  return <span>{timeFormatter(time)} total</span>;
};

export default TotalTime;
