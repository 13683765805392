import React, { ReactElement } from "react";
import { Helmet } from "react-helmet";

export const Seo = (title: string): ReactElement => (
  <Helmet defer={false}>
    <title>{title}</title>
    <html lang="en" />
    <meta name="description" content="An interval timer to help with exercising" />
  </Helmet>
);
