import React, { FC, ReactElement, useState } from "react";
import style from "../styles/base.module.scss";
import loadable from "@loadable/component";
import Presets from "../components/Presets/Presets";
import { Preset } from "../interfaces/Preset";
import { Seo } from "../helpers/Seo/Seo";
import Loading from "../components/Loading/Loading";

const Create = loadable(() => import("../components/Create/Create"));
const Settings = loadable(() => import("../components/Settings/Settings"));
const TimerOverlay = loadable(() => import("../components/TimerOverlay/TimerOverlay"));

const Home: FC = (): ReactElement => {
  const [preset, setPreset] = useState<Preset>();

  const reset = () => setPreset(undefined);

  return (
    <div className={style.container}>
      {Seo("Interval Timer")}

      <div className={style.title}>
        <h1>Interval Timer</h1>

        <Settings fallback={<Loading />} />
        <Create setPreset={setPreset} fallback={<Loading />} />
      </div>

      <Presets setPreset={setPreset} />

      <TimerOverlay preset={preset} reset={reset} fallback={<Loading />} />
    </div>
  );
};

export default Home;
