import React, { Dispatch, Fragment, ReactElement, SetStateAction, useState } from "react";
import style from "../../styles/panel.module.scss";
import usePresets from "../../hooks/UsePresets/UsePresets";
import { Button } from "../Inputs/Button/Button";
import TotalTime from "../TotalTime/TotalTime";
import { Preset as PresetInterface } from "../../interfaces/Preset";
import { Interval } from "../../interfaces/Intervals";
import { timeFormatter } from "../../helpers/TimeFormatter/TimeFormatter";
import PlayButton from "../Inputs/PlayButton/PlayButton";
import { plural } from "../../helpers/Plural/Plural";

interface Props {
  preset: PresetInterface;
  index: number;
  setPreset: Dispatch<SetStateAction<PresetInterface | undefined>>;
}

const Preset = (props: Props): ReactElement => {
  const [presets, setPresets] = usePresets();
  const [open, setOpen] = useState<boolean>(false);

  const toggle = (): void => setOpen(!open);

  const start = (): void => props.setPreset(props.preset);

  const remove = (): void => {
    const newPresets = [...presets];
    newPresets.splice(props.index, 1);

    setPresets(newPresets);
  };

  const lastSet: number = props.preset.intervals[props.preset.intervals.length - 1].set;
  const intervals: Interval[] = props.preset.intervals.filter((interval: Interval) => interval.set === lastSet);

  return (
    <div className={`${style.panel} ${open ? style.active : ""}`}>
      <div className={style.heading}>
        <span className={style.name} onClick={toggle}>
          {props.preset.name}

          <svg
            viewBox="0 0 24 24"
            width="18"
            height="18"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            fill="none"
            shapeRendering="geometricPrecision"
          >
            <path d="M6 9l6 6 6-6" />
          </svg>
        </span>

        <PlayButton onClick={start} />
      </div>

      <div className={style.content} aria-hidden={!open}>
        <div className={style.inside}>
          <div className={style.spaceBetween}>
            <span>
              {lastSet} {plural("set", lastSet > 1)}
            </span>

            <TotalTime intervals={props.preset.intervals} />
          </div>
          <div className={style.centered}>
            {intervals.map(
              (interval: Interval, index: number): ReactElement => {
                const isLast = index === intervals.length - 1;

                return (
                  <Fragment key={index}>
                    {timeFormatter(interval.seconds)}
                    {isLast ? "" : ", "}
                  </Fragment>
                );
              }
            )}
          </div>

          <div className={style.buttons}>
            <Button text="Delete" fullWidth className="tertiary" onClick={remove} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Preset;
