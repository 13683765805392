import React, { Dispatch, ReactElement, SetStateAction } from "react";
import baseStyle from "../../styles/base.module.scss";
import usePresets from "../../hooks/UsePresets/UsePresets";
import { Preset as PresetInterface } from "../../interfaces/Preset";
import Preset from "../Preset/Preset";

interface Props {
  setPreset: Dispatch<SetStateAction<PresetInterface | undefined>>;
}

const Presets = (props: Props): ReactElement => {
  const [presets] = usePresets();

  return (
    <>
      <h2 className={baseStyle.h2}>Presets</h2>

      {presets.map((preset: PresetInterface, index: number) => (
        <Preset key={index} preset={preset} index={index} {...props} />
      ))}

      {presets.length < 1 && <p>Easily start your saved presets here!</p>}
    </>
  );
};

export default Presets;
