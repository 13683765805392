import React, { ReactElement } from "react";

const Loading = (): ReactElement => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 38 38" stroke="#fff" role="progressbar">
    <g fill="none">
      <g transform="translate(1 1)" strokeWidth="2">
        <circle cx="18" cy="18" r="18" strokeOpacity="0.5" />
        <path d="M36 18c0-9.9-8.1-18-18-18">
          <animateTransform
            attributeName="transform"
            type="rotate"
            from="0 18 18"
            to="360 18 18"
            dur="1s"
            repeatCount="indefinite"
          />
        </path>
      </g>
    </g>
  </svg>
);

export default Loading;
